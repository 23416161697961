import React from 'react'
import mbdLogoWithdivider from './mbd-footer-logo/footer-logo-with-bar.svg';
import mbdLogoWithOutDivider from './mbd-footer-logo/footer-logo-without-bar.svg';

export const FooterRed = () => {
  return (
    <div className="footer-red bg-danger w-100">
        <div className="red-footer-line  container-lg w-100 d-flex justify-content-center">
            <p className="my-1 fw-500 text-white me-2 my-2 text-nowrap">Powered by</p>
            <img src={mbdLogoWithdivider} alt="" className='d-none d-lg-block' />
            <img src={mbdLogoWithOutDivider} alt="" className='d-block d-md-none' />
            <p className="my-1 fw-500 text-white mx-2 my-2 text-nowrap d-none d-md-block">Branding & Marketing Solutions</p>
            <a role={'button'} className="btn bg-white rounded-0 text-danger ms-2 px-4 fw-600 text-nowrap" href="https://martleybizdesign.com/" target="_blank" rel="noreferrer"> VISIT PAGE</a>
        </div>
    </div>
  )
}
